import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Send from '@mui/icons-material/Send';
import { Grid, IconButton, TextField } from '@mui/material';

import Conversation from 'components/common/Conversation';
import Pusher from 'pusher-js';
import { useCreateChatMessageMutation, useGetChatUserMessagesQuery } from 'services/api/chat.api';

import ChatWrapper from './ChatWrapper';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const DemoChat = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const localStorageKey = `relif-pusher-demo-${companyIdentifier}`;
  const userPhoneIdentifier = localStorage.getItem(localStorageKey);
  const navigate = useNavigate();
  const [messageToSend, setMessageToSend] = useState('');
  const [createDemoChatMessage] = useCreateChatMessageMutation();

  const { data: messages, refetch } = useGetChatUserMessagesQuery({
    userPhoneIdentifier: userPhoneIdentifier ?? '',
  });
  const [refetchFlag, setRefetchFlag] = useState<number>(1);

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching messages');
    });
  }, [refetchFlag]);
  if (!userPhoneIdentifier) {
    // navigate to PusherSignin
    navigate(`/demoChat/${companyIdentifier}/newChat`);
  }

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, { cluster: 'sa1' });

    const channel = pusher.subscribe(`chat-${userPhoneIdentifier}`);
    channel.bind('new-message', (data: object) => {
      setRefetchFlag((prev) => prev + 1);
      console.log('New message', JSON.stringify(data)); // eslint-disable-line no-console
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${userPhoneIdentifier}`);
      pusher.disconnect();
    };
  }, [userPhoneIdentifier, setRefetchFlag]);

  const handleSendMessage = () => {
    if (!userPhoneIdentifier || !messageToSend) {
      return;
    }
    createDemoChatMessage({ userPhoneIdentifier, message: messageToSend })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
        setMessageToSend('');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending demo chat message', error);
      });
  };

  return (
    <ChatWrapper companyIdentifier={companyIdentifier} includeGoToNewChatButton>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Conversation conversation={messages || []} scheduledMessages={[]} isClientView />
        <Grid container width="100%" p={2} justifyContent="space-between">
          <TextField
            id="outlined-basic-email"
            label="Mensaje"
            fullWidth
            multiline
            sx={{
              maxWidth: '80%',
              backgroundColor: '#fff',
              borderRadius: '40px',
              opacity: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': { borderRadius: '40px' },
            }}
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
          />
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            onClick={handleSendMessage}
          >
            <Send
              sx={{
                fontSize: '2.5rem',
              }}
            />
          </IconButton>
        </Grid>
      </div>
    </ChatWrapper>
  );
};

export default DemoChat;
