import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Stack, TextField } from '@mui/material';

import AlertDialog from 'components/UI/AlertDialog';
import { format } from 'rut.js';
import { useCreateChatUserMutation } from 'services/api/chat.api';
import getReCaptchaToken from 'utils/getReCaptchaToken';

import ChatWrapper from './ChatWrapper';

const mapAttributes = (key: string) => {
  if (key === 'rut') {
    return 'Rut';
  }
  if (key === 'email') {
    return 'Email';
  }
  if (key === 'firstName') {
    return 'Nombre';
  }
  if (key === 'lastName') {
    return 'Apellido';
  }
  return key;
};

const NewDemoChat: React.FC = () => {
  const [openForm, setOpenForm] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    rut: '',
    email: '',
  });

  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const localStorageKey = `relif-pusher-demo-${companyIdentifier}`;
  const clientPhoneIdentifier = localStorage.getItem(localStorageKey);
  const [createDemoChatUser, { data: createUserData, isError }] = useCreateChatUserMutation();
  const navigate = useNavigate();
  if (!companyIdentifier) {
    navigate('/demoChat'); // Error page
    return null;
  }

  const startChat = () => {
    getReCaptchaToken()
      .then((recaptchaToken) => {
        createDemoChatUser({ companyIdentifier, recaptchaToken, userData }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error creating demo chat user', error);
          setOpenError(true);
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error getting recaptcha token', error);
        setOpenError(true);
      });
  };

  const navigateToChat = () => {
    navigate(`/demoChat/${companyIdentifier}`);
  };

  useEffect(() => {
    if (createUserData) {
      localStorage.setItem(localStorageKey, createUserData.id);
      navigateToChat();
    }
  }, [createUserData]);

  useEffect(() => {
    if (isError) {
      setOpenError(true);
    }
  }, [isError]);

  return (
    <ChatWrapper companyIdentifier={companyIdentifier}>
      <>
        <AlertDialog
          openDialog={openError}
          setOpenDialog={setOpenError}
          header="Ha ocurrido un error iniciando el chat"
          msg="Intenta nuevamente, con otros datos"
        />
        {openForm ? (
          <Stack spacing={2} pt={2} style={{ backgroundColor: 'white' }}>
            {Object.entries(userData)
              .filter(([k]) => !k.includes('brand'))
              .map(([key, value]) => (
                <TextField
                  key={key}
                  label={mapAttributes(key)}
                  value={value}
                  onChange={(e) =>
                    setUserData({ ...userData, [key]: key === 'rut' ? format(e.target.value) : e.target.value })
                  }
                  fullWidth
                />
              ))}
            <Button variant="contained" color="primary" onClick={startChat}>
              Iniciar Chat
            </Button>
          </Stack>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Button variant="contained" color="primary" onClick={startChat}>
              Iniciar Chat
            </Button>
            {['salfa-nurturing', 'pompeyo-chat', 'auto-chat'].includes(companyIdentifier) && (
              <Button variant="contained" color="primary" onClick={() => setOpenForm(true)}>
                Iniciar Chat con Datos
              </Button>
            )}

            {clientPhoneIdentifier && (
              <Button variant="contained" color="primary" onClick={navigateToChat}>
                Continuar chat
              </Button>
            )}
          </Box>
        )}
      </>
    </ChatWrapper>
  );
};

export default NewDemoChat;
