import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { checkIfTokenIsValid } from 'authStorage';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

import useAccessToken from './useAccessToken';

const useIsCajaLosAndesTheme = (): boolean => {
  const [getUser, user] = useLazyGetUserInformationQuery();
  const location = useLocation();
  const pathnameParts = location.pathname.split('/');
  const token = useAccessToken();

  useEffect(() => {
    if (checkIfTokenIsValid(token)) {
      getUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [token]);

  if (user.data && user.data.company?.name === 'Caja Los Andes') {
    return true;
  }
  if (
    (pathnameParts.includes('convenios') || pathnameParts.includes('onboarding')) &&
    pathnameParts.includes('caja-los-andes')
  ) {
    return true;
  }
  return false;
};

export default useIsCajaLosAndesTheme;
