import React, { FC, useState } from 'react';

import { Box, Grid, Container, Typography, Checkbox, FormControlLabel } from '@mui/material';

import LogoKaufmann from 'assets/images/logos/kaufmann.webp';
import useQuery from 'hooks/useQuery';
import { format } from 'rut.js';
import { useCreateProspectMutation } from 'services/api/prospects.api';

const KaufmannProspects: FC = () => {
  const query = useQuery();
  const [createProspect, { isLoading }] = useCreateProspectMutation();
  const [message, setMessage] = useState<string>('');
  const [email, setEmail] = useState<string>(query.get('email') || '');
  const queryRut = query.get('rut');
  const [rut, setRut] = useState<string>(queryRut ? format(queryRut) : '');
  const [phone, setPhone] = useState<string>(query.get('phone') || '');
  const [meeting, setMeeting] = useState<boolean>(true);

  const rutFormatter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setRut(format(event.target.value));
    }
    if (!event.target.value && rut === '-') {
      setRut('');
    }
  };
  const signMandate = () => {
    if (!email) {
      setMessage('Debes completar al menos el correo. Intenta nuevamente');
      return;
    }
    setMessage('Enviando ... (puede tomar unos segundos)');
    createProspect({
      email,
      rut,
      phone,
      reason: `Kaufmann pilot register with${meeting ? '' : 'out'} meeting`,
    })
      .then((response) => {
        // eslint-disable-next-line no-prototype-builtins
        if (response.hasOwnProperty('error')) {
          throw new Error('Api error');
        }
        setEmail('');
        setRut('');
        setPhone('');
        setMessage('¡Gracias! Estaremos en contacto');
      })
      .catch((error) => {
        setMessage('Error al validar tus datos. Intente nuevamente');
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };
  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <div
        style={{
          backgroundImage: "url('./relif_background.webp')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100vw',
          filter: 'blur(10px)',
          margin: 0,
          padding: 0,
        }}
      />
      <Container
        sx={{
          backgroundImage: 'url("/contact-background.svg")',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: '30px',
          padding: { lg: '20px 50px 50px', xs: '20px 10px' },
          position: 'absolute',
          mt: 3,
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -30%)',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <Box flexDirection={{ xs: 'column', lg: 'row' }} alignItems="center" display="flex" mt={1} mb={2}>
            <img alt="Kaufmann" src={LogoKaufmann} height="60px" />
            <img alt="Relif" src="/relif_logo_white.webp" height="100px" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mr: 2,
              ml: 2,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { lg: '40px', xs: '25px' },
                width: '100%',
                mb: '30px',
                textTransform: 'uppercase',
                fontFamily: 'Roboto',
                fontWeight: 800,
                color: 'white',
                textAlign: 'center',
              }}
            >
              Programa de Salud Financiera
            </Typography>
          </Box>
          <Box className="contact-form-container" flexDirection="column" width={{ xs: '100%', lg: '70%' }}>
            <form className="contact-form" style={{ flexDirection: 'column' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <input
                    type="email"
                    name="email"
                    id="form-email"
                    placeholder="Email"
                    required
                    className="sign-mandate-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <input
                    id="rut"
                    name="rut"
                    placeholder="Rut"
                    className="sign-mandate-input"
                    value={rut}
                    onChange={rutFormatter}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <input
                    type="text"
                    name="phone"
                    id="form-document-number"
                    placeholder="Celular"
                    className="sign-mandate-input"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
              </Grid>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    color: 'white',
                  },
                }}
                control={
                  <Checkbox checked={meeting} color="info" onChange={(event) => setMeeting(event.target.checked)} />
                }
                label="Me interesa asistir a una charla presencial para saber más sobre el programa"
              />
              <Typography color={message.includes('nuevamente') ? 'orange' : 'white'} pt={1}>
                {message}
              </Typography>
              <button
                type="button"
                className="sign-mandate-button"
                onClick={(e) => {
                  e.preventDefault();
                  signMandate();
                }}
                disabled={isLoading || !email}
              >
                Enviar
              </button>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default KaufmannProspects;
