import { Message } from '../types/whatsApp';
import baseApi from './baseApi';

const publicAccessApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createChatUser: build.mutation({
      query: (props: {
        companyIdentifier: string;
        recaptchaToken: string;
        isFromWidget?: boolean;
        userData?: {
          firstName: string;
          lastName: string;
          rut: string;
          email: string;
        };
      }) => ({
        url: '/chat/users',
        method: 'POST',
        body: {
          companyIdentifier: props.companyIdentifier,
          recaptchaToken: props.recaptchaToken,
          isFromWidget: props.isFromWidget,
          userData: props.userData,
        },
      }),
      transformResponse: (response: { id: string }) => response,
    }),
    getChatUserMessages: build.query({
      query: (props: { userPhoneIdentifier: string }) => ({
        url: `/chat/messages?userPhoneIdentifier=${props.userPhoneIdentifier}`,
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
    createChatMessage: build.mutation({
      query: (props: { userPhoneIdentifier: string; message: string }) => ({
        url: '/chat/messages',
        method: 'POST',
        body: props,
      }),
      transformResponse: (response: { status: string }) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useCreateChatUserMutation, useGetChatUserMessagesQuery, useCreateChatMessageMutation } = publicAccessApi;
