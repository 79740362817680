import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { RollbarContext } from '@rollbar/react';
import useCurrentRoute from 'hooks/useCurrentRoute';
import { capturePageView } from 'utils/posthogUtils';

import AuthRoute from './authRoute';
import RoutesMapping from './routes';

const SITE_KEY = import.meta.env.VITE_RECAPTCHA_CLIENT_KEY || '';
const NODE_ENV = import.meta.env.NODE_ENV || 'development';

const AppRoutes = () => {
  const { title, path } = useCurrentRoute();
  useEffect(() => {
    document.title = title ? `Relif - ${title}` : 'Relif';
  }, [title]);

  const routesWithRecaptcha = [
    '/welcome',
    '/welcome/usa',
    '/welcome/en',
    '/welcome/es',
    '/welcome/cl',
    '/welcome/br',
    '/welcome/pt',
    '/sign-up',
    '/sign-in',
    '/password-reset',
    '/alianza-work-cafe',
    '/convenios/:id',
    '/blog',
    '/blog/:slug',
    '/caja-los-andes-debt-flow',
    '/banco-chile-debt-flow',
    '/banco-chile-debt-flow-iansa',
    '/onboarding/:companyName',
  ];

  const fullPath = window.location.pathname;
  const loadScriptByURL = (id: string, url: string) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      script.src = url;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      script.id = id;
      document.body.appendChild(script);
    }
  };

  const removeScript = (id: string) => {
    const script = document.getElementById(id);
    if (script) {
      document.body.removeChild(script);
    }
  };

  useEffect(() => {
    if (NODE_ENV === 'test') {
      return;
    }
    if (routesWithRecaptcha.includes(path) || fullPath.includes('newChat')) {
      loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
    } else {
      removeScript('recaptcha-key');
      const recaptchaElements = document.getElementsByClassName('grecaptcha-badge');

      if (recaptchaElements.length) {
        recaptchaElements[0].remove();
      }
    }
    capturePageView();
  }, [path]);
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Routes>
        {Object.values(RoutesMapping).map((route) => {
          return (
            <Route
              key={route.title}
              path={route.path}
              element={
                <RollbarContext context={route.path}>
                  <AuthRoute route={route} />
                </RollbarContext>
              }
            />
          );
        })}
      </Routes>
    </React.Suspense>
  );
};

export default AppRoutes;
