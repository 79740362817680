import React, { FC, ReactNode, useEffect, useRef } from 'react';

import { AccessAlarm } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Launch from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CheckIcon, ClockIcon, DeleteIcon } from 'assets/icons';
import { DeleteBtn } from 'components/UI/Buttons';
import { useDeleteScheduledMessageMutation } from 'services/api/whatsApp.api';
import { Message, WhatsAppScheduledTemplateMessage } from 'services/types/whatsApp';

const getExtension = (fileName: string): string => {
  // file.png -> png, file.ogg; codecs=opus -> ogg
  return fileName.split(';')[0].split('.').at(-1) as string;
};
const urlify = (text: string): ReactNode[] => {
  const urlRegex = /(https?:\/\/[^\s",\\)]+|\*+\S.+?\S\*+)/g;
  const nodes: ReactNode[] = text.split(urlRegex).map((textSegment, index) => {
    if (textSegment.startsWith('http')) {
      return (
        <a
          href={textSegment}
          key={`${textSegment}-${index + 1}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          {textSegment}
        </a>
      );
    }
    if (textSegment.startsWith('*') && textSegment.endsWith('*')) {
      return <strong key={`${textSegment}-${index + 1}`}>{textSegment.slice(1, -1)}</strong>;
    }
    return textSegment;
  });
  return nodes;
};

interface Props {
  conversation: Message[];
  scheduledMessages: WhatsAppScheduledTemplateMessage[];
  fullHeight?: boolean;
  isClientView?: boolean;
  isAssistantWriting?: boolean;
}

const Conversation: FC<Props> = ({ conversation, scheduledMessages, fullHeight, isClientView, isAssistantWriting }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [conversation]);
  const [deleteScheduledMessage] = useDeleteScheduledMessageMutation();

  const getMessageHTML = (message: Message) => {
    let text = '';
    // if (message.fileName) {
    //   text += message.fileName; // url
    // }
    // if (message.mediaId) {
    //   text += `-Archivo: ${message.mediaId}`;
    // }
    if (message.role === 'assistant' && message.functionName && message.functionArguments) {
      text += `Asistente: ${message.functionName}(${message.functionArguments?.replaceAll('\n', '')})`;
    } else if (message.role === 'function') {
      text += `Función: ${message.text?.replaceAll('\\', '').replace(/(^")|("$)/g, '')}`;
    } else {
      text += message.text ?? '';
    }
    text += '\n';

    const isSpecialStatus = [
      'failed',
      'deleted',
      'discarded',
      'warning',
      'functionSuggestion',
      'functionResponse',
      'suggestion',
      'neverSent',
    ].includes(message.status);

    return (
      <>
        {message.conversation && message.conversation.length > 0 && (
          <div
            style={{
              position: 'relative',
              float: 'right',
              top: '0px',
            }}
            title="Replicar este mensaje en el playground"
          >
            <a
              href={`/playground/${message.id}`}
              style={{ textDecoration: 'none', margin: 0, padding: 0 }}
              target="_blank"
              rel="noreferrer"
            >
              &gt;
            </a>
          </div>
        )}{' '}
        {text && (!message.fileName || getExtension(message.fileName) === 'ogg') && (
          <Typography
            variant="body1"
            px={1}
            sx={{
              whiteSpace: 'pre-line',
              textAlign: 'left',
              wordBreak: 'break-word',
              color: isSpecialStatus ? 'grey' : undefined,
            }}
          >
            {urlify(text)}
          </Typography>
        )}
        {message.url && (
          <a href={message.url} target="_blank" rel="noreferrer">
            <Typography variant="body2" px={1}>
              {message.fileName}
            </Typography>
          </a>
        )}
        {message.fileName && ['png', 'jpeg', 'jpg', 'webp', 'mp4'].includes(getExtension(message.fileName)) && (
          <>
            {getExtension(message.fileName) === 'mp4' && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video width="100%" autoPlay muted controls>
                <source
                  src={message.fileName.startsWith('https') ? message.fileName : message.url || undefined}
                  type="video/mp4"
                />
              </video>
            )}
            {getExtension(message.fileName) !== 'mp4' && (
              <img
                src={message.fileName.startsWith('https') ? message.fileName : message.url || undefined}
                alt="chat-file"
                style={{ maxWidth: '100%', maxHeight: message.fileName.includes('webp') ? '120px' : '250px' }}
              />
            )}
            <br />
            {text && (
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: 'pre-line',
                  textAlign: 'left',
                  wordBreak: 'break-word',
                  color: isSpecialStatus ? 'grey' : undefined,
                }}
              >
                {text.replace('Imagen enviada', '')}
              </Typography>
            )}
          </>
        )}
        {message.fileName && !['png', 'jpeg', 'jpg', 'webp', 'mp4', 'ogg'].includes(getExtension(message.fileName)) && (
          <Box
            p={1}
            sx={{
              backgroundColor: message.role === 'user' ? '#f3f5f5' : '#d8f3ce',
              borderRadius: '10px',
            }}
          >
            <Button
              href={message.fileName.startsWith('https') ? message.fileName : message.url || ''}
              target="_blank"
              rel="noreferrer"
              variant="text"
              color="inherit"
              sx={{ textTransform: 'none', width: '100%', display: 'block' }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <InsertDriveFileIcon
                    sx={{
                      height: '1.8em',
                      width: '1.8em',
                    }}
                  />
                  <Stack pr={10} pl={1} textAlign="left">
                    <Typography variant="body1" sx={{ color: '#111b21' }}>
                      {message.text
                        ? message.text
                            .split(':')
                            .pop()
                            ?.replace(/[a-z0-9]*-/g, '')
                        : message.fileName.split('/').pop()}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#111b21' }} fontSize={15}>
                      {getExtension(message.fileName).toLocaleUpperCase()}
                    </Typography>
                  </Stack>
                </Box>
                <DownloadIcon
                  sx={{
                    border: 'solid',
                    borderRadius: '100%',
                    borderWidth: 'thin',
                    opacity: '80%',
                    padding: '2px',
                    height: '1.6em',
                    width: '1.6em',
                  }}
                />
              </Box>
            </Button>
          </Box>
        )}
        <Box display="inline-flex" alignItems="center">
          <Typography variant="body2" p={1} sx={{ color: 'grey' }}>
            {new Date(message.createdAt).toLocaleTimeString('es-CL', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
          </Typography>
          {message.status === 'sent' && <CheckIcon />}
          {(message.status === 'delivered' || message.status === 'received') && (
            <>
              <CheckIcon sx={{ marginRight: '-16px' }} />
              <CheckIcon />
            </>
          )}
          {message.status === 'read' && (
            <>
              <CheckIcon color="info" sx={{ marginRight: '-16px' }} />
              <CheckIcon color="info" />
            </>
          )}
          {message.status === 'sent to api' && <ClockIcon />}
          {isSpecialStatus && <Typography variant="body2">{message.status}</Typography>}
          {message.emoji && (
            <div
              style={{
                height: '26px',
                width: '30px',
                position: 'relative',
                float: 'right',
                bottom: '-20px',
                backgroundColor: 'white',
                borderRadius: '50px',
                textAlign: 'center',
                border: '1px solid rgba(0,0,0,0.05)',
              }}
            >
              {message.emoji}
            </div>
          )}
        </Box>
      </>
    );
  };
  const getScheduledMessageHTML = (scheduledMessage: WhatsAppScheduledTemplateMessage) => {
    return (
      <>
        <Typography variant="h4" px={1} sx={{ whiteSpace: 'pre-line', textAlign: 'left', color: 'grey' }}>
          {scheduledMessage.text}
        </Typography>
        <Box display="inline-flex" alignItems="center">
          <Typography variant="h5" p={1}>
            <ClockIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
            {new Date(scheduledMessage.postAt).toLocaleTimeString('es-CL', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
          </Typography>
          <Typography variant="h5">{scheduledMessage.status}</Typography>
        </Box>
      </>
    );
  };
  const getMessageButtons = (buttons: Message['buttons']) =>
    buttons && (
      <Stack pb={1} spacing={1}>
        {buttons.map((button) => (
          <div key={`${button.id} - ${button.title}`}>
            <Divider />
            <Box textAlign="center" alignSelf="center">
              {button.url ? (
                <Button
                  variant="text"
                  startIcon={<Launch fontSize="small" />}
                  href={button.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {button.title}
                </Button>
              ) : (
                button.title
              )}
            </Box>
          </div>
        ))}
      </Stack>
    );

  return (
    <Box height={fullHeight ? '100%' : undefined} display="flex" flexDirection="column">
      {conversation?.map((message) => (
        <Box
          key={message.id}
          m={1}
          p={1}
          pb={0}
          sx={{
            maxWidth: '70%',
            borderRadius: '12px',
            boxShadow: '0 1px 0.5px rgba(11,20,26,.13)',
            ...((!isClientView && message.role !== 'user') || (message.role === 'user' && isClientView)
              ? {
                  backgroundColor: '#d9fdd2',
                  textAlign: 'end',
                  alignSelf: 'flex-end',
                }
              : {
                  backgroundColor: '#fff',
                  textAlign: 'start',
                  alignSelf: 'flex-start',
                }),
          }}
        >
          {getMessageHTML(message)}
          {getMessageButtons(message.buttons)}
        </Box>
      ))}
      {isAssistantWriting && (
        <Box
          m={1}
          p={1}
          pb={0}
          sx={{ maxWidth: '70%', borderRadius: '12px', backgroundColor: '#f3f5f5', textAlign: 'start' }}
        >
          <Typography variant="body1" px={1}>
            Escribiendo...
          </Typography>
        </Box>
      )}
      {scheduledMessages?.map((scheduledMessage) => (
        <Box
          key={scheduledMessage.id}
          textAlign="end"
          alignSelf="flex-end"
          display="flex"
          m={1}
          p={1}
          pb={0}
          sx={{
            maxWidth: '70%',
          }}
        >
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <AccessAlarm fontSize="large" />
            <DeleteBtn
              onClick={() => {
                deleteScheduledMessage(scheduledMessage.id)
                  .then(() => {
                    alert('Debes recargar la página para ver los cambios'); // eslint-disable-line no-alert
                  })
                  .catch(console.error); // eslint-disable-line no-console
              }}
            >
              <DeleteIcon fontSize="large" />
            </DeleteBtn>
          </Box>
          <Box
            sx={{
              padding: 1,
              pb: 0,
              ml: 1,
              backgroundColor: '#d9fdd2',
              borderRadius: '12px',
              boxShadow: '0 1px 0.5px rgba(11,20,26,.13)',
            }}
          >
            {getScheduledMessageHTML(scheduledMessage)}
            {getMessageButtons(scheduledMessage.buttons)}
          </Box>
        </Box>
      ))}
      <div ref={messagesEndRef} />
    </Box>
  );
};

Conversation.defaultProps = {
  fullHeight: false,
  isClientView: false,
  isAssistantWriting: false,
};

export default Conversation;
