/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

// @ts-ignore
import { DataTable } from '@dscla/ds-data-table/react';
// @ts-ignore
import priceFormat from 'services/format/priceFormat';
import { DebtToCategorize } from 'services/types/debt';
import showDebtIcon from 'utils/showDebtIcon';

interface DebtTableInterface {
  debtList: DebtToCategorize[];
}

const DebtSummaryTable: FC<DebtTableInterface> = ({ debtList }) => {
  const getInstitutionAvatar = (debt: DebtToCategorize) => (
    <Avatar
      sx={{
        bgcolor: '#EDF1FA',
        color: 'primary.main',
        width: '35px',
        height: '35px',
      }}
    >
      {showDebtIcon(debt)}
    </Avatar>
  );
  const getTotalPaymentLabel = (debt: DebtToCategorize) => priceFormat.format(Number(debt.totalAmount));

  const getTableRow = (debt: DebtToCategorize) => (
    /* the tags data-mobile and data-desktop determine what td will be shown */
    <tr key={`debt-${debt.institutionName}-${debt.debtType}`}>
      <td data-first="" data-mobile-width="100%" data-mobile>
        <Box display="flex" justifyContent="space-between" alignContent="center">
          <Box>
            {debt.institutionName}
            <br />
            {debt.debtType && debt.debtType.charAt(0).toUpperCase() + debt.debtType.slice(1)}
          </Box>
          <Box>{getInstitutionAvatar(debt)}</Box>
        </Box>
      </td>
      <td data-desktop>{getInstitutionAvatar(debt)}</td>
      <td data-desktop data-label={debt.institutionName} data-border-none="">
        {debt.debtType && debt.debtType.charAt(0).toUpperCase() + debt.debtType.slice(1)}
      </td>
      <td data-label="Monto total" data-mobile data-mobile-width="33%">
        {getTotalPaymentLabel(debt)}
      </td>
      <td data-desktop>{getTotalPaymentLabel(debt)}</td>
    </tr>
  );

  return (
    <DataTable variant="card">
      <table slot="card">
        <thead>
          <tr>
            <td>Tipo</td>
            <td>Institución</td>
            <td>Deuda Total</td>
          </tr>
        </thead>
        <tbody>{debtList.map((debt) => getTableRow(debt))}</tbody>
      </table>
    </DataTable>
  );
};

export default DebtSummaryTable;
